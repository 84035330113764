import DetailsModal from "../../components/DetailsModal";
import React, { useState, useEffect } from "react";
var UserMgtModal = function (props) {
    var _a;
    var _b = useState(true), showSuccessModal = _b[0], setShowSuccessModal = _b[1];
    useEffect(function () {
        var _a;
        setShowSuccessModal((_a = props.showModal, (_a !== null && _a !== void 0 ? _a : false)));
    }, [props.showModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DetailsModal, { showModal: showSuccessModal, onClose: function () {
                props.onClose();
            }, onCloseRedirect: function () {
                props.onCloseRedirect();
            }, uid: (_a = props.uid, (_a !== null && _a !== void 0 ? _a : "")) })));
};
export default UserMgtModal;
