export var getFullName1 = function (client) { var _a, _b, _c; return ((_a = client.personalInfo.client1) === null || _a === void 0 ? void 0 : _a.fName) + " " + ((_b = client.personalInfo.client1) === null || _b === void 0 ? void 0 : _b.mName) + " " + ((_c = client.personalInfo.client1) === null || _c === void 0 ? void 0 : _c.lName); };
export var getFirstName1 = function (client) { var _a; return "" + ((_a = client.personalInfo.client1) === null || _a === void 0 ? void 0 : _a.fName); };
export var getMiddleName1 = function (client) { var _a; return "" + ((_a = client.personalInfo.client1) === null || _a === void 0 ? void 0 : _a.mName); };
export var getDOB1 = function (client) { var _a; return "" + ((_a = client.personalInfo.client1) === null || _a === void 0 ? void 0 : _a.dob); };
export var getMphone1 = function (client) { var _a; return "" + ((_a = client.personalInfo.client1) === null || _a === void 0 ? void 0 : _a.mPhone); };
export var getEmail1 = function (client) { var _a; return "" + ((_a = client.personalInfo.client1) === null || _a === void 0 ? void 0 : _a.email); };
export var getMarital1 = function (client) { var _a; return "" + ((_a = client.personalInfo.client1) === null || _a === void 0 ? void 0 : _a.marital); };
export var getFullName2 = function (client) { var _a, _b, _c; return ((_a = client.personalInfo.client2) === null || _a === void 0 ? void 0 : _a.fName) + " " + ((_b = client.personalInfo.client2) === null || _b === void 0 ? void 0 : _b.mName) + " " + ((_c = client.personalInfo.client2) === null || _c === void 0 ? void 0 : _c.lName); };
export var getFirstName2 = function (client) { var _a; return "" + ((_a = client.personalInfo.client2) === null || _a === void 0 ? void 0 : _a.fName); };
export var getMiddleName2 = function (client) { var _a; return "" + ((_a = client.personalInfo.client2) === null || _a === void 0 ? void 0 : _a.mName); };
export var getDOB2 = function (client) { var _a; return "" + ((_a = client.personalInfo.client2) === null || _a === void 0 ? void 0 : _a.dob); };
export var getMphone2 = function (client) { var _a; return "" + ((_a = client.personalInfo.client2) === null || _a === void 0 ? void 0 : _a.mPhone); };
export var getEmail2 = function (client) { var _a; return "" + ((_a = client.personalInfo.client2) === null || _a === void 0 ? void 0 : _a.email); };
export var getMarital2 = function (client) { var _a; return "" + ((_a = client.personalInfo.client2) === null || _a === void 0 ? void 0 : _a.marital); };
