import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal as MaterialUIModal, Fade, Backdrop } from "@material-ui/core";
var useStyles = makeStyles(function (theme) { return ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: 825,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}); });
var Modal = function (props) {
    var classes = useStyles();
    return (React.createElement(MaterialUIModal, { className: classes.modal, open: props.showModal, onClose: props.onClose, closeAfterTransition: true, BackdropComponent: Backdrop, BackdropProps: {
            timeout: 500,
        } },
        React.createElement(Fade, { in: props.showModal },
            React.createElement("div", { className: classes.paper }, props.children))));
};
export default Modal;
