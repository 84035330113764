var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Ajv from "ajv";
import Cookies from "js-cookie";
import clientSummaryResponseSchemaJson from "../schemas/clientSummaryResponseSchema.json";
var ajv = new Ajv({
    allErrors: true,
    removeAdditional: "all",
    // JSON Schema defaults could be used
    // https://json-schema.org/understanding-json-schema/reference/generic.html#annotations
    // useDefaults: true,
    schemas: [clientSummaryResponseSchemaJson],
});
var validateSummaryResponse = ajv.getSchema("clientSummaryResponseSchema.json");
var _fetch = function (path, init) {
    if (init === void 0) { init = {}; }
    var token = Cookies.get("token");
    return fetch(process.env.REACT_APP_API_URL + path, __assign(__assign({}, init), { referrer: window.location.href, headers: __assign(__assign({}, init.headers), { Authorization: "Bearer " + token }) }));
};
var message = "";
export var fetchSummary = function (uid) {
    return _fetch("client/" + uid + "/clientSummary")
        .then(function (res) { return res.json(); })
        .then(function (summaryResponse) {
        var errors = validateSummaryResponse.errors;
        var isValid = validateSummaryResponse(summaryResponse);
        if (!isValid) {
            return { kind: "ERROR", errors: errors };
        }
        return { kind: "SUCCESS", value: summaryResponse.clientSummary };
    })
        .catch(function (error) { return ({
        kind: "ERROR",
        errors: error,
        value: null,
    }); });
};
export var deleteUser = function (uid) {
    return _fetch("users/" + uid, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid: uid }),
    })
        .then(function (res) {
        if (res.status !== 200) {
            throw new Error(res.status);
        }
        return res.json();
    })
        .then(function (res) {
        message = JSON.stringify(res);
        return message;
    })
        .catch(function (err) {
        console.log("caught :", err.message);
        throw err;
    });
};
