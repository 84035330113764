/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import styles from "./ClientResponse.module.scss";
import Modal from "./Modal";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import moment from "moment";
import { fetchSummary, deleteUser } from "../lib/services";
import { getFullName1, getFullName2, getDOB1, getDOB2, getMphone1, getMphone2, } from "../lib/utils";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(3),
            },
            fontSize: "20",
        },
        root2: {
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        formControl: {
            margin: theme.spacing(3),
        },
    });
});
var DetailsModal = function (props) {
    var _a;
    var classes = useStyles();
    var _b = useState({ kind: "LOADING", value: null }), summaryRes = _b[0], setSummaryRes = _b[1];
    var summary = summaryRes.value;
    var initiateData = function () {
        Promise.all([
            fetchSummary(props.uid),
        ])
            .then(function (_a) {
            var client = _a[0];
            setSummaryRes(client);
        })
            .catch(function (error) { return console.log("Error in promises :", { error: error }); });
    };
    useEffect(function () {
        initiateData();
    }, [props.uid]);
    var _c = useState(""), callMessage = _c[0], setCallMessage = _c[1];
    var _d = useState(false), deleteButton = _d[0], setDeleteButton = _d[1];
    var _e = React.useState(false), loading = _e[0], setLoading = _e[1];
    var _f = React.useState(""), status = _f[0], setStatus = _f[1];
    var buttonClassname = clsx((_a = {},
        _a[classes.buttonSuccess] = status === "success" ? true : false,
        _a));
    useEffect(function () {
        if (deleteButton === true) {
            setLoading(true);
            deleteUser(props.uid)
                .then(function (mess) {
                if (!isNaN(mess)) {
                    setCallMessage("");
                    setStatus(mess);
                }
                else {
                    setCallMessage(mess);
                    setStatus("success");
                    // initiateData()
                }
                setLoading(false);
                setDeleteButton(false);
            })
                .catch(function (err) {
                console.log("useEffect catch :", err);
                setStatus("failed");
                setLoading(false);
                setDeleteButton(false);
            });
        }
    }, [deleteButton]);
    if (summaryRes.kind === "ERROR" || summaryRes.kind === "LOADING") {
        return React.createElement("span", null, "Error");
    }
    var severity;
    var alertTitle = "";
    var alertMessage = "";
    switch (status) {
        case "":
            severity = "info";
            alertTitle = "User Management";
            alertMessage = "If you choose to delete this user then all records will be removed from the database and cannot be recovered.";
            break;
        case "success":
            severity = "success";
            alertTitle = "Records deleted";
            alertMessage = "Sucessfully deleted the selected record, you will not be able to recover data.";
            break;
        case "failed":
            severity = "error";
            alertTitle = "Failed to delete user";
            alertMessage = "Unknown error occured. Please contact helpdesk for assistance.";
            break;
        case "401":
            severity = "error";
            alertTitle = "Failed to delete user";
            alertMessage = "You are not authorised to delete users. Please contact helpdesk for assistance.";
            break;
        case "500":
            severity = "error";
            alertTitle = "Failed to delete user";
            alertMessage = "Server side error 500, this indicates that the server encountered an unexpected condition that prevented it from fulfilling the request. Please contact helpdesk for assistance.";
            break;
        default:
            severity = "error";
            alertTitle = "Failed to delete user";
            alertMessage = "Unknown error occured. Please contact helpdesk for assistance.";
            break;
    }
    var renderInsertedAccount = function (status) {
        if (status === "success") {
            return (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("span", { style: { fontSize: "13px", fontWeight: "bold" } },
                    "Deletion request processed: ",
                    React.createElement("i", { style: { fontWeight: "bold" } }, JSON.parse(callMessage)[3].status)),
                React.createElement("br", null),
                React.createElement("span", { style: { fontSize: "12px" } },
                    "User id: ",
                    React.createElement("i", { style: { fontWeight: "bold" } }, JSON.parse(callMessage)[0].userid)),
                React.createElement("br", null),
                React.createElement("span", { style: { fontSize: "12px" } },
                    "User login: ",
                    React.createElement("i", { style: { fontWeight: "bold" } }, JSON.parse(callMessage)[0].status)),
                React.createElement("br", null),
                React.createElement("span", { style: { fontSize: "12px" } },
                    "Client data: ",
                    React.createElement("i", { style: { fontWeight: "bold" } }, JSON.parse(callMessage)[1].status)),
                React.createElement("br", null),
                React.createElement("span", { style: { fontSize: "12px" } },
                    "Change history: ",
                    React.createElement("i", { style: { fontWeight: "bold" } }, JSON.parse(callMessage)[2].status))));
        }
    };
    return (React.createElement(Modal, { showModal: props.showModal, onClose: props.onClose },
        React.createElement("div", { className: classes.root },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, lg: 12, style: { marginTop: "15px" } },
                    React.createElement(Alert, { severity: severity },
                        React.createElement(AlertTitle, { style: { fontSize: "15px", fontWeight: "bold" } }, alertTitle),
                        React.createElement("span", { style: { fontSize: "12px" } }, alertMessage),
                        renderInsertedAccount(status))),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                    React.createElement("h4", { style: { color: "#FE9A2E" } }, "Primary Client (Main)"),
                    React.createElement("div", { style: { marginBottom: "3px" } },
                        React.createElement("b", null, "Name"),
                        ": ",
                        React.createElement("br", null),
                        getFullName1(summary)),
                    React.createElement("div", { style: { marginBottom: "3px" } },
                        React.createElement("b", null, "Date of Birth"),
                        ": ",
                        React.createElement("br", null),
                        moment(getDOB1(summary)).format("MMMM D, YYYY")),
                    React.createElement("div", { style: { marginBottom: "3px" } },
                        React.createElement("b", null, "Mobile Phone"),
                        ": ",
                        React.createElement("br", null),
                        getMphone1(summary))),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                    React.createElement("h4", { style: { color: "#FE9A2E" } }, "Secondary Client (Partner)"),
                    React.createElement("div", { style: { marginBottom: "3px" } },
                        React.createElement("b", null, "Name"),
                        ": ",
                        React.createElement("br", null),
                        getFullName2(summary)),
                    React.createElement("div", { style: { marginBottom: "3px" } },
                        React.createElement("b", null, "Date of Birth"),
                        ": ",
                        React.createElement("br", null),
                        moment(getDOB2(summary)).format("MMMM DD, YYYY")),
                    React.createElement("div", { style: { marginBottom: "3px" } },
                        React.createElement("b", null, "Mobile Phone"),
                        ": ",
                        React.createElement("br", null),
                        "q",
                        getMphone2(summary))),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, lg: 12, style: { marginBottom: "8px" } },
                    (function () {
                        return (React.createElement("div", { className: classes.root2 },
                            React.createElement("div", { className: classes.wrapper },
                                React.createElement(Fab, { "aria-label": "upload", color: "secondary", className: buttonClassname, onClick: function () {
                                        setDeleteButton(true);
                                    } }, status === "success" ? (React.createElement(CheckIcon, null)) : (React.createElement(DeleteForeverRoundedIcon, { color: "inherit", fontSize: "large" }))),
                                loading && (React.createElement(CircularProgress, { size: 68, className: classes.fabProgress }))),
                            React.createElement("div", { className: classes.wrapper },
                                React.createElement(Button, { variant: "contained", color: "secondary", className: buttonClassname, disabled: loading, onClick: function () {
                                        setDeleteButton(true);
                                    } }, "Delete All records"),
                                loading && (React.createElement(CircularProgress, { size: 24, className: classes.buttonProgress })))));
                    })(),
                    React.createElement("div", null,
                        React.createElement(Button, { variant: "contained", color: "primary", className: styles.secondaryButton, onClick: function () {
                                setLoading(false);
                                setDeleteButton(false);
                                setCallMessage("");
                                if (status === "success") {
                                    props.onCloseRedirect();
                                }
                                props.onClose();
                                setStatus("");
                            } }, "Exit")))))));
};
export default DetailsModal;
